@import "../../styles/variables.scss";

.popular-trip-main {
  background-color: #000;
  overflow: hidden;
  .PopularTripBooking {
    //background-image: url('../../assets/hp-hero-img.png');
    // padding: 50px 0;
    // background-position-y: center;
    .SearchFlightForm {
      // margin-top:0;
      margin-right: 150px;
      margin-top: 3%;
      margin-bottom: 3%;
      min-width: 345px;
    }
    .Popular-Routes-container{
      position: relative;
    }
    .Popular-Routes-new {
      width: 270px;
      margin: 0px auto 0px;
      color: #fff;
      text-align: center;
      padding-bottom: 30px;
    }

    .search {
      display: flex;
      //max-width: 1380px;
      width: 100%;
      // min-height: 900px;
      //max-height: 900px;
      margin: auto;
      -webkit-flex-wrap: none;
      flex-wrap: none;
      padding: 0;
      position: relative;
      .join-popup {
        .modal-content {
          background: #3881ef;
          display: -webkit-flex;
          display: flex;
          -webkit-flex-direction: column;
          flex-direction: column;
          -webkit-align-items: center;
          align-items: center;
          color: #fff;
          width: 590px;
         // height: 680px;
          background: #3881EF;
          button.yellow-btn {
            font-size: 20px;
            font-weight: 600;
          }
        }
      }
    }

    .left-section-popular {
      margin-top: 3%;
      margin-left: 10%;
      max-height: 200px;
      position: relative;
      z-index: 2;
      color: white;
      font-size: 21px;
      margin-right: 10%;
    }

    .popularVideo {
      position: absolute;
      width: 100%;
      height: auto;
      object-fit: cover;
      top:0px;
    }
  }
}

@media screen and ($tablet-media-min-hz) and ($tablet-media-hz) {
  .popular-trip-main {
    .PopularTripBooking {
      .search{
        flex-flow: column-reverse;
        width: auto;
        margin: 0px 15px;
        .left-section-popular{
          width: 100%;
          margin: 0px;
          max-height: max-content;
        }
      }
    }
  }
  .popular-trip-main .PopularTripBooking .SearchFlightForm{
    margin: 50px auto;
  }
  .popular-trip-main .PopularTripBooking .Popular-Routes-new {
      margin: 50px auto 40px;
  }
}

@media screen and ($tablet-media-min) and ($tablet-media) {
  .popular-trip-main {
    .PopularTripBooking {
      .search{
        flex-flow: column-reverse;
        width: auto;
        margin: 0px 15px;
        .left-section-popular{
          width: 100%;
          margin: 0px;
          max-height: max-content;
        }
      }
    }
  }
  .popular-trip-main .PopularTripBooking .SearchFlightForm{
    margin: 50px auto;
  }
  .popular-trip-main .PopularTripBooking .Popular-Routes-new {
      margin: 50px auto 40px;
  }
}

@media screen and ($mobile-media) {
  .popular-trip-main .PopularTripBooking .Popular-Routes-new{
    margin: 50px auto 40px;
  }
  .popular-trip-main {
    .PopularTripBooking {
      .search{
        flex-flow: column-reverse;
        width: auto;
        margin: 0px 15px;
        .left-section-popular{
          width: 100%;
          margin: 0px;
          max-height: max-content;
        }
        .SearchFlightForm{
          min-width: 280px;
          width: 87%;
          margin-left:auto !important;
          margin-right:auto !important;
          margin-bottom: 50px;
          .Button.large-btn{
            font-size: 14px;
            height: 35px;
            padding-left: 10px;
            padding-right: 10px;
          }
        }
        .popularVideo {
          position: relative;
        }
      }
    }
  }
  
}