@import "src/styles/variables";

.FlightAddOns {
  background-color: $grey1;
  padding: 20px;
  border-radius: 25px;
  margin-bottom: 30px;

  .head {
    padding-bottom: 20px;
    border-bottom: 1px solid $grey2;
    font-weight: 600;
    font-size: 22px;
  }

  .body-AddOns {


    .addons {
      display: flex;
      padding-top: 20px;
      padding-bottom: 20px;
      width: 100%;
      border-bottom: 1px solid $grey2;

      &:last-child {
        border-bottom: 0;
        padding-bottom: 0;
      }

      > .logo {
        padding-left: 10px;
        padding-right: 10px;
        align-self: center;
      }

      .styled-checkbox {
        height: 40px;
        width: 40px;
        border-radius: 12px;
        margin-right: 10px;
        align-self: center;
        border: 2px solid $grey2;
        padding: 3px;

        &.checkable {
          cursor: pointer;
        }

        > .checked {
          height: 30px;
          width: 30px;
          border-radius: 8px;
          // background-color: $yellow;
        }
      }

      .details {
        align-self: center;

        .title {
          margin-bottom: 0;
          font-size: 18px;
          font-weight: 600;
        }


        .price {
          margin-bottom: 0;
          font-size: 16px;
          color: $grey3;
        }
      }

      .link {
        font-size: 16px;
        align-self: center;
        margin-left: auto;
        cursor: pointer;
        color: $black;
      }
      .requested{
        cursor: default;
      }
      .link:hover {
        text-decoration: none;
      }
    }
  }
}

@media screen and ($mobile-media) {
  .logo {
      width: 25%;
  }
  .FlightAddOns .body-AddOns .addons .details .title {
      font-size: 14px;
  }
  .FlightAddOns .body-AddOns .addons .link {
      font-size: 14px;
  }
}