@import "src/styles/variables";
.iq-main {
    background-color: $black;
}

.bg-img{
    background-image: url('../../assets/IQ-bg.jpg');
    width: 100%;
    background-repeat: no-repeat;
    text-align: center;
    max-width: 1380px;
    margin: 0 auto;
   // margin-left: 12%;
   img.laptop-img{
    width:100%;
    max-width: 1100px;
    height:auto;
    margin:0px auto;
    padding:0px 20px;
   }
}


.AB1-Investor-Slide{
    margin-left: auto;
    margin-right: auto;
    display: block;
    width: 100%;
    max-width: 1050px;
    margin-top:30px;
}

.iq-root {   

max-width: 1160px;
margin: auto;
width: 100%;
color: #f1f4f9;
padding-top: 5%;
padding-bottom: 170px;
padding-left:20px;
padding-right:20px;
img{
    width:100%;
    height:auto;
    max-width:1100px;
    margin-top:100px;
    margin-left: 70px;
}
}

.iq-title{
    color: #f1f4f9;
    font-weight: 600;
    font-size: 48px;
    line-height: 53px;
    text-align: left;
    text-align: center;
    margin-top: 50px;
    
}  

.iq-statements{
    max-width: 1300px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 130px;
    grid-row-gap: 260px;
    margin: 0 auto;
    padding: 0px 25px;
    padding-bottom: 140px;
    .Statement{
        .icon{
            max-width: 50px;
            max-height: 50px;
            width: auto;
            height: auto;
        }
        .text{
            margin-top: 33px;
            color: $white;
            font-weight: 600;
        }
       
    }
  
  }



.iq-empty{
    width: 1440px;
    height: 100px;
}



.iq-demo{
  
    .laptop-img{
        margin-top: -185px;
    margin-left: -50px;
    }
}

.iq-mission{
    color: #f1f4f9;
    font-weight: 700;
    font-size: 72px;
    line-height: 80px;
    font-style: normal;
    text-align: left;
    line-height: 120%;
    text-align: center;
}


.iq-setup-demo{
    width: 199px;
    height: 59px;
    font-weight: 600;
    background: #3881EF;
    border-color: #3881EF; 
    border-radius: 30px;
    font-size: 18px;
    line-height: 23px;
    color: #FFF;
    border: 0px solid;

}

.iq-setup{
    padding-left: 40%;
    padding-top: 100px;
}

.iq-setup-mission{
    //padding-left: 43%;
    padding-top: 40px;
    text-align: center;
}


.iq-root-mission {   
    
    max-width: 996px;
    margin: auto;
    width: 100%;
    color: #f1f4f9;
    padding-top:100px;
    }
    .iq-root-mission-bottom {   
    
        max-width: 996px;
        margin: auto;
        width: 100%;
        color: #f1f4f9;
        padding-top:230px;
        }
    @media screen and ($tablet-media-min) and ($tablet-media) {
    .iq-statements{
        grid-gap: 18px;
    }
    .iq-root-mission {  
        max-width: 500px;
    }
    .iq-root-mission-bottom {  
        max-width: 500px;
    }
    .iq-root img{
        margin-left: 20px; 
    }

    }

    @media screen and ($mobile-media) {

        .iq-root img{
            width: 110%;
            margin-left: -5px;
            }
    .bg-img img.laptop-img {
        margin-top: 120px!important;
        padding: 0px 0px 0px 5px;
        }

        .iq-mission {
            padding-top: 35px!important;
        }
        
        .iq-sub-header{
            display: none;
        }

        .how-we-build{
            display: none;
        }

        .iq-container{
            margin-bottom:50px;
        }

        .how-we-build-mobile{
            display: block!important;
            h3{
                font-size: 48px;
                color: #ffffff;
                font-weight: 700;
                text-align: center;
                margin-top:120px;
            }
            img{
                width:100%;
                height: auto;
                max-width: 230px;
                margin: 50px auto 20px auto;
            }
        }

        .iq-setup-demo{
           text-decoration: none;
           
        }
        .iq-root-mission {   
    
            max-width: 996px;
            margin: auto;
            width: 100%;
            color: #f1f4f9;
            padding-top: 100px;
           
            }
            .iq-root-mission-bottom {   
    
                max-width: 996px;
                margin: auto;
                width: 100%;
                color: #f1f4f9;
                padding-top: 230px;
               
                }    
            .iq-mission{
                font-size: 54px;
                padding-top:20px;
                margin: auto;
                padding: 0px 10px;
            }
            .iq-statements{
              display: flex;
              flex-direction : column;
            
            //   display: grid;
            //   grid-template-columns: 1fr 1fr 1fr;
              grid-gap: 50px;
              //grid-row-gap: 260px;
              margin: 0 auto;
              padding: 0px 25px;
              //padding-bottom: 140px;
            }
            .iq-empty{
                width: 1440px;
                height: 2px;
            }

            .iq-title {
                font-size: 40px !important;
                margin-top: 50px !important;
                margin-bottom: 0px !important;
                max-width: 350px;
                margin-left: auto;
                margin-right: auto;
            }
     
    }

   

   