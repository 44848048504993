@import '../../styles/variables.scss';

.general-section-priv-policy {
    width: 100%;
    max-width: 968px;
    margin: 5% auto;
    padding:0 20px;
  }
  
@media screen and ($tablet-media-min) and ($tablet-media) {
    .general-section-priv-policy {
        ul{
          padding:10px;
        }
        p{
          margin:0;
        }
      }
}

@media screen and ($mobile-media) {
    
    .general-section-priv-policy {
        ul{
          list-style-type: none;
          li{
            p{
                margin-left: 20px !important;
            }
          }   
        }
        p{
          margin:0 !important;
        }
        table,tr{
          width:100px !important;
          margin:0px auto !important;
        }
        td{
            width:25% !important;
            p{
                font-size:2px;
            }
        }
      }
}