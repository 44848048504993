@import "src/styles/variables";

.PopularTrips {
  background-color: $black;
  .Cloud1 {
    background-image: url("../../assets/cloud-1.jpg");
    background-size: 800px !important;
  }
  .Cloud2 {
    background-image: url("../../assets/cloud-2.jpg");
    background-size: 850px !important;
  }
  .Cloud3 {
    background-image: url("../../assets/cloud-3.jpg");
    background-size: 650px !important;
  }
  .Cloud4 {
    background-image: url("../../assets/cloud-4.jpg");
    background-size: 700px !important;
  }
  .Cloud5 {
    background-image: url("../../assets/cloud-5.jpg");
    background-size: cover;
  }
  .Cloud6 {
    background-image: url("../../assets/cloud-6.jpg");
    background-size: cover;
  }
  .Cloud1,
  .Cloud2,
  .Cloud3,
  .Cloud4,
  .Cloud5,
  .Cloud6 {
    background-position-y: center;
    background-position-x: center;
  }
  .TopPicture {
    background-size: cover !important;
  }
  .BottomPicture {
    background-position-y: bottom;
    background-size: unset;
  }
  .PopularContainer {
    margin: 0 auto;
    max-width: 1366px;
    padding: 20px;
    .Title {
      color: $white;
      text-align: center;
      font-weight: 600;
      margin-bottom: 25px;
    }
    .TopSection {
      margin-bottom: 70px;
      .PopularCard-1 {
        position: relative;
        margin-bottom: 30px;
        width: 100%;
        padding: 35px;
        height: 300px;
        border-radius: 20px;
        cursor: pointer;
        p {
          color: $white;
        }
        .routeName {
          font-size: 35px;
          width: 293px;
        }
        .CodeSection {
          display: flex;
          flex-direction: row;
          position: absolute;
          bottom: 25px;
          img {
            height: 20px;
            width: auto;
            margin: 0 5px;
          }
        }
      }
      .PopularCardContainer {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-column-gap: 30px;
        margin-bottom: 30px;
        .PopularCard-2 {
          position: relative;
          width: 100%;
          height: 250px;
          padding: 35px;
          border-radius: 20px;
          cursor: pointer;
          p {
            color: $white;
          }
          .routeName {
            font-size: 28px;
            width: 233px;
          }
          .CodeSection {
            display: flex;
            flex-direction: row;
            position: absolute;
            bottom: 15px;
            img {
              height: 20px;
              width: auto;
              margin: 0 5px;
            }
          }
        }
      }
      .PopularCardGrid {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        grid-gap: 30px;
        margin-bottom: 30px;
        .PopularCard-3 {
          width: 100%;
          height: 350px;
          border-radius: 20px;
          display: flex;
          flex-direction: column;
          justify-content: flex-end;
          cursor: pointer;
          .inner-content {
            position: relative;
            height: 60%;
            padding: 20px;
            background-color: rgba(0, 0, 0, 0.5);
            p {
              color: $white;
            }
            .routeName {
              font-size: 21px;
              width: 175px;
            }
            .CodeSection {
              display: flex;
              flex-direction: row;
              position: absolute;
              bottom: 15px;
              p {
                font-size: 13px;
              }
              img {
                height: 17px;
                width: auto;
                margin: 0 5px;
              }
            }
           
          }
        }
      }
      .button-div{
        display: flex;
        justify-content: center;
        padding-top: 30px;
      .link-button {
        color: white;
        background-color: #000;
        border: 1px solid #ffffff;
        box-sizing: border-box;
        border-radius: 100px;
        font-weight: 600;
        font-size: 18px;
        line-height: 23px;
        padding: 5px 25px;
      }
    }
}

    .BottomSection {
      .Title {
        color: $white;
        text-align: center;
        font-weight: 600;
        margin-bottom: 60px;
      }
      .scroller {
        display: block;
        margin: 0 auto;
        margin-bottom: 80px;
      }
      #MoreRoutes {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        grid-gap: 100px;
        grid-row-gap: 50px;
        .PopularCard-4 {
          display: flex;
          flex-direction: row;
          border-bottom: 1px solid $grey7;
          justify-content: space-evenly;
          cursor: pointer;
          svg {
            flex-basis: 10%;
            color: $white;
          }
          .PlaceContainer {
            flex-basis: 45%;
            .dept,
            .arrival {
              width: fit-content;
              margin: 0 auto;
            }
            .place {
              color: $white;
              margin: 0;
            }
            .code {
              color: $grey4;
              font-size: 13px;
            }
          }
        }
      }
    }
  }
}

@media screen and ($tablet-media-min) and (max-width: 950px) {
  .PopularTrips {
    .PopularContainer {
      .TopSection {
        .PopularCardGrid {
          grid-template-columns: 1fr 1fr;
        }
      }
      .BottomSection {
        #MoreRoutes {
          grid-template-columns: 1fr 1fr;
          grid-gap: 50px;
        }
      }
    }
  }
}

@media screen and ($mobile-media) {
  .PopularTrips {
    .Cloud1,
    .Cloud2,
    .Cloud3,
    .Cloud4,
    .Cloud5,
    .Cloud6 {
      background-size: cover;
      background-position-y: center;
    }
    .PopularContainer {
      .TopSection {
        .PopularCard-1 {
          height: 400px;
          .routeName {
            width: 280px;
          }
        }
        .PopularCardContainer {
          grid-template-columns: 1fr;
          grid-gap: 30px;
        }
        .PopularCardGrid {
          grid-template-columns: 1fr;
          .PopularCard-3 {
            height: 150px;
            .inner-content {
              height: 100%;
              background-color: unset;
              .CodeSection {
                bottom: 0px;
              }
            }
          }
        }
      }
      .BottomSection {
        #MoreRoutes {
          grid-template-columns: 1fr;
          grid-gap: 50px;
        }
      }
    }
  }
}
