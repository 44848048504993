@import "src/styles/variables";

.join-popup{
  .modal {
    .modal-content{
    .close {
       opacity: 1;
       color: white;
      }
    }
  }
  
}

.account {
  color: #ebff0c;
  font-size: 16px;
  font-weight: 600;
  padding-top: 7%;
}

.benefit {
  font-size: 48px;
  font-weight: 600;
  line-height: 20px;
  padding-bottom: 20px;
}

.benefit-points {
  display: flex;
  padding-top: 8px;
  p{
    font-size: 16px;
  padding-top: 5px;
  font-weight: 400;
  line-height: 20px;
  margin-bottom: 0px;
  }
  img {
    height: 20px;
    margin-right: 10px;
    position: relative;
    top: 4px;
  }
}

.message{
  text-align: center;
  font-size: 18px;
  font-weight: 500;
  padding-top: 40px;
  line-height: 23px;
}

.login-btns{
    width: 245px;
    margin: 0 auto;
    .Button {
      margin-top: 0; 
      padding: 0px 0px!important;
      // .yellow-btn{
      // }
    }
}

.contact-links{
  max-width: 151.71px;
  margin: 0 auto;
  display: flex;
  gap: 24px;
  padding-top: 20px;
}

@media screen and ($mobile-media) {
  .benefit{
    padding-bottom: 20px;
  }
  .benefit-points {
    p{
      line-height: 20px;
      padding-top: 5px;
    }
  }
}

@media screen and ($tablet-media-min) and ($tablet-media) {
  .benefit-points {
    p{
      line-height: 20px;
      padding-top: 5px;
    }
  }
}
