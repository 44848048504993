@import "src/styles/variables";

.MyTripsCard {
  padding: 25px;
  margin-bottom: 20px;
  border-radius: 24px;
  border: 1px solid transparent;
  cursor: pointer;

  &:hover {
    border: 1px solid $grey3;
  }

  &.active {
    background-color: $grey1;
  }

  .header {
    height: 50px;

    .status {
        font-weight: 600;
        font-size: 18px;
        line-height: 23px;
      &.RESERVED {
        color: $blue;
      }

      &.CANCELLED {
        color: $red;
      }
    }
  }

  > img {
    width: 100%;
    padding-bottom: 30px;
  }
  
  > .details-container {
    
    >.main-info-container{
      // display: flex;
      border-bottom: 1px solid $grey2;
      padding-bottom: 20px;

      > .title-container {
        display: flex;

        > .booking-id {
          margin-bottom: 0;
          font-size: 16px;
          font-weight: 600;
        }

        > .legs {
          margin-bottom: 0;
          margin-left: auto;
          color: $grey3;
        }
      }
    }
  }

  .airport-container {
    padding-top: 20px;
    padding-bottom: 20px;
    border-bottom: 1px solid $grey2;
    display: flex;

    .icon-container {
      padding-right: 10px;

      .v-line {
        height: 40px;
        border-left: 2.5px dotted $black;
        margin-left: 7px;
        margin-bottom: -10px;
        margin-top: -4px;
      }
    }

    .places-container {
      width: 100%;

      .airport-detail {
        display: flex;
        width: 100%;
        margin-bottom: 0;

        &:first-child {
          border-bottom: 1px solid $grey2;
          padding-bottom: 15px;
        }

        &:last-child {
          padding-top: 15px;
        }

        .a-name {
          margin-bottom: 0;
          font-size: 14px;
          font-weight: 500;
        }

        .dot {
          margin-top: 7px;
          margin-left: 10px;
        }

        .city-name {
          margin-left: 10px;
          margin-bottom: 0;
          font-size: 12px;
        }

        .time {
          align-self: center;
          margin-left: auto;
          width: 30%;
          text-align: end;
          float: right;
          font-size: 11.5px;
          margin-bottom: 0;
          min-width: 65px;
        }
      }

    }
  }

}
@media screen and ($tablet-media-min) and ($tablet-media) {
  .time {
    width: 45%;
  }
}

@media screen and ($tablet-media-min-hz) and ($tablet-media-hz) {
  .time {
    width: 45%;
  }
}