@import 'src/styles/variables';

.learn-more-section {
  position: static;
  max-width: 1440px;
  width: 95%;
  height: 800px;
  left: 0px;
  top: 1431px;
  margin: auto;
}

.learn-more-text {
  display: flex;
}

.learn-more-img {
  background: #ffffff;
  max-width: 1440px;
  width: 100%;
  height: 750px;
  border-radius: 20px;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)),
  url('../../assets/view-more.jpg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.learn-more-title {
  position: relative;
  width: 500px;
  height: 96px;
  top: 327px;
  font-style: normal;
  font-weight: bold;
  font-size: 48px;
  line-height: 100%;
  color: $grey1;
  display: flex;
  align-items: center;
}

.text-align-left {
  right: 5%;
  margin:auto;
}

.text-align-center {
  left: 40%;
}

.arrow-right {
  position: relative;
  display: block;
  margin: auto;
  width: 50px;
  height: 50px;
  // left: 17%;
  // top: 250px;
  transform: rotate(-90deg);
  cursor: pointer;
}

.arrow-down {
  position: relative;
  width: 50px;
  height: 50px;
  left: 50%;
  top: 500px;
  cursor: pointer;
}

@media screen and ($mobile-media) {
  .learn-more-title {
    font-size: 36px;
    // left: 20%;
    right:0;
    width: 60%;
    text-align: center;
    top: 210px;
  }

  .learn-more-section .learn-more-title{
    display: block;
  }
  .learn-more-section .learn-more-title img{
    display: block;
    margin-top: 30px;
  }

  .arrow-right{
    left:0;
    
  }

  .learn-more-img {
    width: 90%;
    margin: auto;
    left: 10%;

  }
}

@media screen and ($tablet-media-min) and ($tablet-media) {
  .text-align-left {
    left: 0;
  }

  .learn-more-title {
    max-height: 300px;
  }
}
