@import "src/styles/variables";

.BookingDetails {
  > .body {
    min-height: 400px;
  }
}

.BookingDetailsHeader {
  background-color: $black;
  color: $white;
  padding-top: 65px;
  padding-bottom: 43px;

  h2 {
    font-style: normal;
    font-weight: 600;
    font-size: 32px;
    line-height: 41px;
    padding-bottom: 20px;
  }

  .details {
    display: flex;
    margin: auto;
    max-width: $page-width;
    width: 95%;

    > h1 {
      width: $page-width;
      font-size: 60px;
      font-weight: 600;
      line-height: 54px;
      padding-bottom: 15px;
    }

    > .status {
      margin-left: 0;
      background-color: $yellow;
      padding: 15px;
      border-radius: 32px;
      height: 54px;
      color: $black;
      font-weight: bold;

      &.RESERVED {
        background-color: $blue;
        color: $white;
      }

      &.CANCELLED {
        background-color: $red;
        color: $white;
      }
    }
  }
}

.attention {
  margin: 10px 0;
  display: flex;
  flex-direction: row;
  padding-bottom: 25px;
  .attention-logo {
    max-width: 30px;
    max-height: 30px;
  }
  .attention-info {
    margin-left: 10px;
    font-size: 18px;
    margin-bottom: 0%;
    align-self: center;
  }
}

.DetailsPanel {
  max-width: $page-width;
  width: 95%;
  margin: 50px auto;

  .planeCarousel{
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .PlaneCarousel {
    margin-bottom: 30px;
  }

  .payment-instruction {
    background-color: $grey1;
    padding: 20px;
    margin-bottom: 30px;
    border-radius: 30px;
    display: flex;

    .info-container {
      align-self: center;

      .pre-label {
        margin-bottom: 0;
        font-weight: normal;
        font-size: 14px;
        line-height: 18px;
        color: $grey3;
      }

      .label {
        margin-bottom: 0;
        font-weight: bold;
        font-size: 21px;
        line-height: 26px;
      }
    }

    .action-container {
      display: flex;
      align-items: flex-end;
      margin-left: auto;

      button {
        &:first-child {
          margin-right: 20px;
        }
      }
    }
  }

  > .details-container {
    max-width: $page-width - 300;
    margin: auto;
  }
}

.PassengersPanel {
  max-width: $page-width;
  width: 95%;
  margin: auto;

  .action-container {
    margin-top: 30px;
    display: flex;

    .action {
      padding: 20px;
      display: flex;
      cursor: pointer;
      margin-right: 20px;
      border: 1px solid transparent;

      &:hover {
        border: 1px solid $grey3 ;
        border-radius: 20px;
      }

      .label {
        margin-left: 20px;
        margin-top: 8px;
        font-weight: 600;
        font-size: 18px;
        line-height: 23px;
        margin-bottom: 0;
      }
    }
  }

  .traveller-container {
    display: flex;
    flex-wrap: wrap;

    > .traveller-tile {
      cursor: pointer;
      display: flex;
      width: 30%;
      margin: 0 1.66%;
      border-bottom: 1px solid $grey2;

      > .order {
        margin-bottom: 0;
        font-weight: 600;
        font-size: 32px;
        line-height: 41px;
        padding: 20px 10px;
      }

      > .name {
        width: 100%;
        margin-bottom: 0;
        padding: 0px 10px;
        font-weight: 600;
        font-size: 18px;
        line-height: 23px;
        align-self: center;
      }

      > .passenger-lead{
        align-self: center;
        width: 100%;
        padding-bottom: 10px;
        > .name {
          width: 100%;
          margin-bottom: 0;
          padding: 20px 10px;
          font-weight: 600;
          font-size: 18px;
          line-height: 23px;
        }
        > .lead{
          position:absolute;
          transform: translate(10px, -20px);
          font-size: 13px;
          font-weight: 400;
          line-height: 15px;
          color: $blue;
        }
      }

      > .tag {
        margin-bottom: 0;
        margin: auto;
        padding: 20px 10px;
        font-weight: 900;
        font-size: 18px;
        line-height: 23px;
        align-self: center;
      }
      > .passenger-legs-msg {
        margin-bottom: 0;
        margin: auto;
        padding: 20px 10px;
        font-weight: 900;
        font-size: 18px;
        line-height: 23px;
        align-self: center;
      }
    }
  }
}

.ReceiptPanel {
  max-width: $page-width;
  width: 95%;
  margin: 50px auto;

  .option-icon {
    margin-right: 10px;
    width: 40px;
  }

  .key-value {
    padding-top: 20px;
    padding-bottom: 20px;
    padding-left: 20px;
    border-bottom: 1px solid $grey2;
    display: flex;
    align-items: center;

    &:nth-child(2) {
      border-top: 1px solid $grey2;
    }

    .key {
      margin-bottom: 0;
      padding-bottom: 5px;
      width: 100%;

      .title {
        color: $black;
        margin-bottom: 10px;
        font-style: normal;
        font-weight: 600;
        font-size: 24px;
        line-height: 26px;

        &.small {
          font-weight: 600;
          font-size: 18px;
          line-height: 23px;
        }

        &.big {
          font-weight: 600;
          font-size: 24px;
          line-height: 26px;
        }
      }

      .sub-title {
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 18px;
        margin-bottom: 0;
        color: $grey3;

        svg {
          margin-right: 10px;
        }

        &.big {
          font-weight: 600;
          font-size: 18px;
          line-height: 23px;
          color: $black;
        }
      }
    }

    .value {
      margin-bottom: 0;
      font-weight: 600;
      margin-left: 0;
      font-size: 24px;
      line-height: 26px;
      padding-right: 20px;
    }
  }
}
.DetailsPanel .payment-instruction .action-container button{
  line-height: 18px;
}

@media screen and ($tablet-media-min) and ($tablet-media) {
  .ReceiptPanel {
    width: 95%;
  }

  .BookingDetailsHeader .details {
    width: 95%;
    > h1 {
      font-size: 50px;
    }
  }

  .PassengersPanel {
    width: 95%;
  }

  .DetailsPanel {
    width: 95%;
  }

  .traveller-container {
    > .traveller-tile {
      width: 45% !important;
      margin: 0 2.5%;
    }
  }
  .DetailsPanel .payment-instruction{display:block;}
  .DetailsPanel .payment-instruction .action-container button{
      line-height: 18px;
  }
}

@media screen and ($mobile-media) {
  .BookingDetailsHeader {
    .details {
      width: 95%;

      > h1 {
        font-size: clamp(31px, 200%, 38px);
        padding-left: 20px;
      }

      > .status {
        height: 48px;
        font-size: 15px;
      }
    }
  }
  .DetailsPanel {
    width: 95%;

    .details-container {
      width: 100%;
      padding: 10px;
    }
  }

  .attention {
    .attention-info {
      font-size: 14px;
    }
  }

  .PassengersPanel {
    width: 100%;

    .action-container {
      margin-top: 30px;
      border-top: 1px solid $grey2;
      display: flex;
      width: 90%;
      margin: 30px auto;
      margin-bottom: 0;
      flex-wrap: wrap;

      .action {
        border-bottom: 1px solid $grey2;
        padding: 20px;
        width: 100%;
        margin: auto;
      }
    }

    .traveller-container {
      .traveller-tile {
        width: 90%;
        margin: auto;
        padding-left: 20px;
        padding-right: 20px;
      }
    }
  }
  .ReceiptPanel {
    width: 95%;

    h2 {
      padding: 10px;
    }
  }
  .DetailsPanel .payment-instruction{display:block;}
  .DetailsPanel .payment-instruction .action-container button{
      line-height: 18px;
  }
}
