@import "src/styles/variables";

/*.gen-temp-left,*/
.gen-temp-right {
  img {
    margin-left: 5%;
    margin-right: 0px;
  }
}

.gen-temp-left {
  display: flex;
  justify-content: flex-start;
  max-width: 1220px;
  width: 100%;
  margin: auto;

  .content {
    width: 350px;
    margin-left: 6%;
    padding-bottom: 170px;

    h2 {
      font-size: 48px;
      font-weight: 600;
      line-height: 52.8px;
      color: $grey1;
      margin-bottom: 8%;
    }

    p {
      font-size: 16px;
      line-height: 24px;
      color: #9ca2a9;
      margin-bottom: 15%;
    }

    button {
      color: white;
      background-color: $black;
      border: 1px solid #ffffff;
      box-sizing: border-box;
      border-radius: 100px;
      font-weight: 600;
      font-size: 18px;
      line-height: 23px;
      padding: 10px 20px 10px 20px;
    }
  }
}

.gen-temp-right {
  display: flex;
  justify-content: flex-end;
  max-width: 1220px;
  width: 100%;
  margin: auto;
  position: relative;
  top: -120px;

  .content {
    width: 350px;
    margin-right: 10%;
    margin-top: 18%;

    h2 {
      font-size: 48px;
      font-weight: 600;
      line-height: 52.8px;
      color: $grey1;
      margin-bottom: 8%;
    }

    p {
      font-size: 16px;
      line-height: 24px;
      color: #9ca2a9;
      margin-bottom: 15%;
    }

    button {
      color: white;
      background-color: $black;
      border: 1px solid #ffffff;
      box-sizing: border-box;
      border-radius: 100px;
      font-weight: 600;
      font-size: 18px;
      line-height: 23px;
      padding: 10px 20px 10px 20px;
    }
  }
}

@media screen and ($mobile-media) {
  .gen-temp-right {
    flex-direction: column-reverse;
  }

  .gen-temp-left,
  .gen-temp-right {
    position: static;
    top: 0;
    flex-wrap: wrap;
    justify-content: unset;

    .content {
      margin: 0;
      padding: 15% 5%;
      width: 100%;

      h3 {
        margin: 2.5% 0;
      }

      button {
        margin: 5% 0;
      }
    }

    img {
      width: 100%;
      margin: 0;
    }
  }
}

@media screen and ($tablet-media-min) and ($tablet-media) {
  .gen-temp-left,
  .gen-temp-right {
    min-height: 300px;
    img {
      width: 45%;
      margin: 0 2.5%;
      object-fit: cover;
    }
  }

  .gen-temp-right {
    .content {
      margin-left: 2.5%;
    }
  }

  .gen-temp-left .content {
    width: 300px;
    margin-bottom: 25%;
    margin-left: 2.5%;
    padding-bottom: 10px;
  }

  .gen-temp-right .content {
    width: 300px;
    margin-right: 2.5%;
    margin-top: 25%;
  }

  .gen-temp-right {
    .content {
      h2,
      p {
        word-break: break-word;
      }
    }
  }

  .gen-temp-left {
    .content {
      h2,
      p {
        margin-right: 5px;
        word-break: break-word;
      }
    }
  }
}

@media screen and ($tablet-media-min-hz) and ($tablet-media-hz) {
  .gen-temp-left,
  .gen-temp-right {
    img {
      width: 45%;
      margin: 0 2.5%;
    }
  }
  .gen-temp-right {
    .content {
      margin-left: 2.5%;
    }
  }
}
