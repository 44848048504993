@import "src/styles/variables";

.AutoComplete {
  position: relative;
  width: 100%;

  .input-field {
    .restriction{
      cursor: pointer;
      display: flex;
      svg{
        align-self: center;
      }
      p{
        color: $blue1;
        position: relative;
        top: 1px;
        font-size: 14px;
        vertical-align: middle;
        margin-bottom: 0;
        padding-left: 10px;
      }
    }
  }

  .suggestions {
    position: absolute;
    background-color: $white;
    border: 1px solid $grey2;
    width: 100%;
    z-index: 20;
    visibility: hidden;
    margin-top: 15px;

    > .tile {
      padding: 15px 20px;

      &:hover {
        background-color: $grey2;
      }
    }

    &.no-result {
      padding: 15px 20px;

      .airport-name {
        display: inline;
        font-weight: bolder;
      }

      .contact-us {

      }
    }

    &.active {
      visibility: visible;
    }
  }

}

.MultiCityForm{
  .AutoComplete {
    .suggestions{
      width: 305px !important;
      max-height: 400px;
      overflow-y: scroll;
    }
  }
}

@media screen and ($tablet-media-min-hz) and ($tablet-media-hz) {
  .MultiCityForm{
    .AutoComplete {
      .suggestions{
        width: 305px !important;
      }
    }
  }
}

@media not screen and ($tablet-media) {
  .MultiCityForm{
    .AutoComplete {
      .suggestions{
        width: 305px !important;
      }
    }
  }
}
@media screen and ($mobile-media) {
  .MultiCityForm{
    .AutoComplete {
      .suggestions{
        width: 100% !important;
      }
    }
  }
}