@import "src/styles/variables";

.Modal {
  .modal {
    background-color: rgba(0, 0, 0, 0.6);
    z-index: 5;
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    overflow: auto;
    //padding-top: 80px;
    -webkit-animation: fadeIn 400ms linear; /* Safari, Chrome and Opera > 12.1 */
    animation: fadeIn 400ms linear;
  }

  .gallery-content {
    width: 100%;
    height: 100%;
    .close {
      color: white;
      position: absolute;
      top: 40px;
      right: 40px;
      opacity: 1;
    }
  }

  .create-login-content {
    float: right;
    max-width: 522px;
    width:100%;
    height: 100%;
    background-color: #000;
    text-align: center;
    .close {
      color: white;
      position: absolute;
      top: 40px;
      right: 40px;
      opacity: 1;
    }
    .middle-logo{
      margin-top: 60px!important;
      margin-bottom: 8%!important;
    }
  }


  .modal-content {
    background-color: white;
    width: 375px;
    max-width: 90%;
    min-height: 250px;
    top: 5%;
    margin: auto;
    padding: 20px;
    border-radius: 20px;
    border: 0;
    text-align: left;

    .title {
      // margin-top: -20px;
      // margin-top: unset;
      // margin-top: -35px;
      font-size: 25px;
    }

    .sub-title {
      margin-top: 0;
      font-size: 20px;
    }

    button {
      &.yellow-btn {
        padding: 7px 35px;
      }
    }
  }

  .close {
    color: black;
    text-align: end;
    float: right;
    font-size: 25px;
    font-weight: bold;
    position: absolute;
    right: 15px;
  }

  .close:hover,
  .close:focus {
    color: #000;
    text-decoration: none;
    cursor: pointer;
  }
}

@media screen and ($mobile-media) {
  .Modal {
    .modal {
      .modal-content {
        margin-left: 20px;
        width: 100%;
        margin-right: 20px;
      }
    }
  }
}
