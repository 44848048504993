@import "src/styles/variables";

html,
body {
  width: 100%;
  margin: 0px;
  padding: 0px;
  overflow-x: hidden;
}

.clear-action {
  position: relative;
  left: 10%;
}

.search-flight {
  position: relative;
  max-width: 1440px;
  width: 100vw;
  min-height: 590px;
  left: 0px;
  top: 0px;
  background: $black;
  flex: none;
  order: 1;
  flex-grow: 0;
  margin: auto;
  margin-bottom: 50px;
  .undersection {
    z-index: 2;
    background-color: rgba(0, 0, 0, 0.8);
    height: 120px;
    position: absolute;
    bottom:0px;
    display: flex;
    justify-content: space-evenly;
    padding-right: 25px;
    width: 100%;
   // margin-top: 3%;
    //margin-top: 10px;
    .Forbes {
      max-width: 275px;
      max-height: 73px;
      width: auto;
      height: auto;
      margin: auto 0;
    }
    .content {
      display: flex;
      align-items: center;
      width: clamp(200px, 32%, 330px);
      p {
        color: #fff;
        min-width: 280px;
        font-weight: 600;
        margin-top: 15px;
      }
      a {
        margin-left: 10px;
      }
      img {
        width: 50px;
        height: 50px;
        transform: rotate(-90deg);
        cursor: pointer;
      }
    }
  }
}

.home-links {
  text-decoration: underline;
}

.home-links:hover {
  color: $blue !important;
}

.logo-tm {
  margin-top: -25px;
  margin-left: 5px;
}

.left-section {
  margin-top: 7%;
  margin-left: 5%;
  position: relative;
  z-index: 1;
  .content {
    padding-top: 20px;
  }

  .logo {
    display: block;
    position: relative;
    width: 90%;
  }
  h5 {
    padding-top: 20px;
    margin-bottom: 60px;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 31px;
    color: $white;
  }

  .search-list {
    display: flex;
    p {
      margin: 0;
      color: $white;
    }
    img {
      height: 18px;
      margin-right: 1%;
      margin-top: 2px;
    }
  }

  .home-saftey-content {
    font-weight: 600;
    font-size: 20px;
    color: #ffffff;
  }

  .home-saftey-sub-content {
    font-weight: 400;
    font-size: 16px;
    padding-bottom: 15px;
    color: #ffffff;
  }

  .download {
    display: flex;
    padding-top: 60px;
    .app-img-link {
      margin-right: 20px;
    }
    .app-img {
      width: 100%;
    }
  }

  .search-btn {
    width: 78px;
    margin-top: 1%;
    display: inline-block;
  }

  .login-btn {
    margin-left: 5%;
    font-size: 18px;
    font-weight: 400;
    color: #ffffff;
    cursor: pointer;
  }
}
.search-img {
  .join-popup {
    .modal-content {
      background: #3881ef;
      display: -webkit-flex;
      display: flex;
      -webkit-flex-direction: column;
      flex-direction: column;
      -webkit-align-items: center;
      align-items: center;
      color: #fff;
      width: 590px;
      min-height: 700px;
      background: #3881EF;
      button.yellow-btn {
        font-size: 20px;
        font-weight: 600;
      }
    }
  }
}

.videoTag {
  position: absolute;
  // top: 0;
  // left: 0;
  z-index: 1; /* Send the video to the background */
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 15px 15px 0 0;
}

.trip-cards{
  margin-top: 12%;
}

// .left-section {
//   position: relative; /* Make sure it's positioned relative to the container */
//   z-index: 1; /* Place it on top of the video */
//   /* Rest of your left-section styles */
// }

// .search-flight-form {
//   position: relative; /* Make sure it's positioned relative to the container */
//   z-index: 1; /* Place it on top of the video */
//   /* Rest of your search-flight-form styles */
// }
.search-flight-form .react-datepicker{ 
  min-width: 328px;
}
.search-flight-form {
  margin: 7% 5% auto auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0;
  min-height: 406px;
  // background: $white;
  border: 1px solid $grey4;
  // box-sizing: border-box;
  //border-radius: 24px;
  position: relative;
  z-index: 3;
  max-width:345px;
  display: -webkit-flex;
  -webkit-flex-direction: column;
  -webkit-align-items: center;
  // align-items: center;
  padding: 0;
  // max-width: 450px;
  // min-height: 385px;
  background: #fff;
  border: 1px solid #d5dce7;
  box-sizing: border-box;
  border-radius: 24px;

  form {
    display: flex;
    flex-wrap: wrap;

    .search-input-grp-date {
      width: 50%;
      border: 0.5px solid #ececec;
      padding: 15px;
      display: flex;

      .clear-action {
        cursor: pointer;
      }

      .input-group {
        display: flex;

        svg {
          cursor: pointer;
        }
      }

      .rdt {
        width: 100%;

        input {
          padding-top: 0;
        }
      }

      .suggestion-title {
        padding-top: 10px;
        padding-bottom: 10px;
        display: flex;

        > .code {
          font-weight: 900;
          align-self: center;
          padding-right: 20px;
        }

        > .info {
          > .title {
            font-weight: 600;
            font-size: 16px;
          }

          > .sub-title {
            font-weight: normal;
            font-size: 13px;
          }
        }
      }

      img {
        margin-right: 10px;
      }

      input[type="text"],
      input[type="date"],
      input[type="number"] {
        width: 100%;
        border: none;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 19px;
        display: flex;
        align-items: center;
        color: $grey3;

        &:focus {
          outline: none;
        }
      }
    }

    .travel-field {
      width: 100% !important;
    }

    .search-input-grp {
      width: 50%;
      border: 0.5px solid #ececec;
      padding: 15px;
      display: flex;

      .clear-action {
        cursor: pointer;
      }

      .input-group {
        display: flex;

        svg {
          cursor: pointer;
        }
      }

      .rdt {
        width: 100%;

        input {
          padding-top: 0;
        }
      }

      .suggestion-title {
        padding-top: 10px;
        padding-bottom: 10px;
        display: flex;

        > .code {
          font-weight: 900;
          align-self: center;
          padding-right: 20px;
        }

        > .info {
          > .title {
            font-weight: 600;
            font-size: 16px;
          }

          > .sub-title {
            font-weight: normal;
            font-size: 13px;
          }
        }
      }

      img {
        margin-right: 10px;
      }

      input[type="text"],
      input[type="date"],
      input[type="number"] {
        //width: 85%;
        width: calc(100% - 40px);
        border: none;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 19px;
        display: flex;
        align-items: center;
        color: $grey3;

        &:focus {
          outline: none;
        }
      }
    }
  }
}

.pets {
  width: 90%;
  display: flex;
  justify-content: space-between;

  p {
    margin: 1px;
  }

  // .abcd{
  //   width: 20px;
  // }

  //    .toggle {
  // //   .react-switch-bg{
  // //     height: 22.54px!important;
  // //     width: 36.07px!important;
  // //     //border-color: #848B94;
  // //     background-color: #ffffff!important;
  // //     border: 1.8px solid #848B94 !important;
  // //     }

  // //   .react-switch-handle{
  // //     height: 22.54px!important;
  // //     width: 22.54px!important;
  // //     border: 1.8px solid #848B94 !important;
  // //     //box-shadow: 0px 0px 0px 0px !important;
  // //     //transform: `translateX(${aria-checked ? '16px' : '0px'})`,
  // //    // transform: translateX(16px)!important;
  // //   }

  //   // .toggle--checked .react-switch-handle {
  //   //   transform: translateX(16px)!important;
  //   // }
  // }
}

.search-btn {
  width: 80%;
  padding: 20px 0;
  margin: auto;
}

.trip-type {
  padding: 2.5%;

  .button-active {
    background-color: $black;
    color: $white;
    border-radius: 25px;
    padding: 12px 16.5px;
    margin: 0 10px;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 19px;
  }

  .button-inactive {
    color: $grey3;
    background-color: $white;
    padding: 12px 16.5px;
    margin: 0 10px;
    border: none;
  }
}

.search-img {
  // max-width: 1380px;
  // width: 100%;
  // height: 87vh;
  // min-height: 590px;
  // max-height: 900px;
  // margin: auto;
  // padding: 0 2%;
  // // border-radius: 20px;
   display: flex;
  // flex-wrap: none;
  // // background-image: url("../../assets/hp-hero-img.png");
  // padding-left: 0px;
  // padding-right: 0px;
  // position: relative;

  max-width: 1380px;
  width: 100%;
  min-height: 820px;
  margin: auto;
  -webkit-flex-wrap: none;
  flex-wrap: none;
  padding: 0;
  position: relative;
  padding-bottom: 180px;
}

.homepage-title {
  max-width: 700px;
  height: 160px;
  margin: auto;
  font-size: 72px;
  line-height: 80px;
  display: flex;
  align-items: center;
  text-align: center;
  color: $grey1;
  margin-bottom: 50px;
  font-weight: 700;
}

.sanitize {
  .sanitize-img {
    max-width: 1220px;
    width: 100%;
    margin: auto;
    img {
      margin: 0% 2.5%;
    }
  }

  .sanitize-content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    // padding: 15% 5%;
    width: 408px;
    height: 256px;
    flex: none;
    order: 0;
    flex-grow: 0;
    margin: 5% auto;
    padding-left: 20px;
    padding-bottom: 25px;

    h3 {
      font-size: 48px;
      line-height: 110%;
      color: $grey1;
    }

    p {
      font-size: 16px;
      line-height: 24px;
      color: #9ca2a9;
    }

    button {
      border: 1px solid $white;
      box-sizing: border-box;
      border-radius: 100px;
      font-style: normal;
      font-weight: 600;
      line-height: 23px;
      color: $white;
      background-color: $black;
      padding: 5px 10px;
      font-size: 18px;
    }
  }
}

.terms-cond {
  display: none;
}

@media screen and ($mobile-media) {

  html {
    width: 100vw;
  }
  .Button.large-btn{
    font-size: 14px !important;
    height: 50px!important;
    padding-left: 12px!important;
    padding-right: 12px!important;
  }
  .SearchFlightForm .react-datepicker-wrapper{min-width:200px;}
  #downloads{
    padding-bottom: 60px;
  }
  .homepage-title {
    font-style: normal;
    font-weight: bold;
    font-size: 48px;
    width: 85%;
    line-height: 100%;
    letter-spacing: -0.01em;
  }
  .videoTag {
    border-radius:0 !important;
  }
  .join-popup .Modal .modal-content{
    top: 10% !important;
    padding-bottom: 50px;
  }
  .left-section .search-btn{
    margin-top:15px;
  }

  // .trip-type {
  //   .Button {
  //     width: 28vw;
  //     font-size: 4vw !important;
  //     padding: 2px !important;
  //   }
  // }

  .undersection {
    .content {
      z-index: 2;
      display: none !important;
    }
  }

  .search-flight {
    max-height: none;
  }

  .search-flight-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0px;
    width: 85%;
    border-radius: 20px;
    background: $white;
    border: 1px solid $grey4;
    border-radius: 20px;
    box-sizing: border-box;
    max-width: 325px;
    margin-left:auto;
    margin-right:auto;
    .trip-type {
      padding: 0.25%;
  }
    .search-input-grp {
      input {
        font-size: 3.2vw !important;
      }
    }
    .search-input-grp-date {
      width: 100% !important;
      input[type="text"],
      input[type="date"],
      input[type="number"] {
        // width: calc(120%) !important;
        font-size: 2.8vw !important;
      }
    }
  }

  // .left-section {
  //   padding-top: 0;
  //   padding-left: 18px;
  //   position: absolute;
  //   .download {
  //     .app-img-link {
  //       margin-right: 18px;
  //     }
  //     a {
  //       width: 45%;
  //       .app-img {
  //         width: 100%;
  //       }
  //     }
  //   }
  //   .join-popup {
  //     .modal-content {
  //       height: 900px;
  //     }
  //   }
  // }

  .left-section {
    // Existing styles...

    @media screen and (max-width: 1024px) {
      margin-left: 5%;
      margin-right: 5%;
      // padding-top: 0;
      // padding-left: 18px;
      position: relative; // Change from absolute to relative for better responsiveness.

      // .download {
      //   .app-img-link {
      //     margin-right: 18px;
      //   }
      //   a {
      //     width: 45%;
      //     .app-img {
      //       width: 100%;
      //     }
      //   }
      // }

      .join-popup {
        .modal-content {
          height: auto; // Remove the fixed height to adapt to content.
        }
      }
    }

    // Add more media queries for smaller screens if needed...
  }

  .search-img {
    flex-wrap: wrap;
    max-height: none;
    margin: 0;
    padding: 0;
    background-size: cover;
    background-position: -150px;
    padding-bottom: 160px;

    .logo {
      // top: 90px;
      // left: 5%;
      width: 90%;
      margin-top: 4%;
      max-width: 630px;
    }

    h5 {
      top: 20%;
      font-size: 5vw;
    }
  }

  .clear-action {
    position: relative;
    left: 20px;
  }

  .input-form-fill {
    width: 100% !important;
  }

  .search-btn {
    padding: 0;
  }

  .sanitize {
    img {
      padding: 0;
      width: 100%;
    }

    .sanitize-content {
      margin: 15% 5%;
    }

    div {
      width: 100%;
      margin-bottom: 30px;
    }

    h3 {
      font-style: normal;
      font-weight: 600;
      font-size: 10vw !important;
      width: 90vw;
      line-height: 110%;
    }

    p {
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 20px;
      width: 85vw;
    }

    button {
      font-size: 16px;
      line-height: 23px;
    }
    .sanitize-img {
      img {
        margin: 0;
        width: 100% !important;
      }
    }
  }

  .terms-cond {
    display: block;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 130%;
    color: $grey3;
    padding: 20px;
    margin-bottom: 0;
  }

  .spacer {
    margin-bottom:0px;
    height: 150px !important;
  }

  .button-active {
    padding: 10px 12.5px !important;
    font-size: 16px !important;
  }

  .button-inactive {
    padding: 10px 12.5px !important;
  }
}
@media screen and ($tablet-media-min-hz) and ($tablet-media-hz) {
  .sanitize {
    .sanitize-img {
      img {
        width: 95%;
        margin: 0 2.5%;
      }
    }
  }
  .iphone-img {
  margin-top: 1rem;
    position: relative;
    left: 50%;
    top: -200px;
    width: 50%;
  }
}

@media screen and ($tablet-media-min) and ($tablet-media) {
  html {
    width: 100vw;
  }
  .join-popup .Modal .modal-content{
    top: 10% !important;
    padding-bottom: 50px;
  }
  .homepage-title {
    font-style: normal;
    font-weight: bold;
    font-size: 48px;
    width: 85%;
    line-height: 100%;
    letter-spacing: -0.01em;
  }
  .videoTag {
    border-radius:0 !important;
  }

  // .trip-type {
  //   .Button {
  //     width: 28vw;
  //     font-size: 4vw !important;
  //     padding: 2px !important;
  //   }
  // }



  .undersection {
    .content {
      z-index: 2;
      display: none !important;
    }
  }

  .search-flight {
    max-height: none;
  }

  .search-flight-form {
    // margin: 3% auto auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0px;
    width: 90%;
    border-radius: unset;
    background: $white;
    border: 1px solid $grey4;
    border-radius: 20px;
    box-sizing: border-box;
    max-width: 450px;
    margin-right: 5%;
    // margin-top: 25%;
    margin-left: auto !important;
    margin-right: auto!important;
    margin-top: 20px;
    max-width: 340px;
    .search-input-grp {
      input {
        // font-size: 3.2vw !important;
      }
    }
    .search-input-grp-date {
      width: 100% !important;
      input[type="text"],
      input[type="date"],
      input[type="number"] {
        // width: calc(120%) !important;
        // font-size: 2.8vw !important;
      }
    }
  }

  .left-section {
    margin-right: 5%;
    // padding-top: 0;
    // padding-left: 18px;
    // .download {
    //   .app-img-link {
    //     margin-right: 18px;
    //   }
    //   a {
    //     width: 45%;
    //     .app-img {
    //       width: 100%;
    //     }
    //   }
    // }
    .join-popup {
      .modal-content {
        height: 900px;
      }
    }
  }

  .search-img {
    flex-wrap: wrap;
    max-height: none;
    margin: 0;
    padding: 0;
    background-size: cover;
    background-position: -150px;
    padding-bottom:180px;

    .logo {
      // top: 90px;
      // left: 5%;
      width: 50%;
      margin-top: 4%;
      // max-width: 630px;
    }

    h5 {
      top: 20%;
      font-size: 22px;
    }
  }

  .clear-action {
    position: relative;
    left: 20px;
  }

  .input-form-fill {
    width: 100% !important;
  }

  .search-btn {
    padding: 0;
  }

  .sanitize {
    div {
      width: 100%;
      margin-bottom: 30px;
    }

    h3 {
      font-style: normal;
      font-weight: 600;
      width: 90vw;
      line-height: 110%;
    }

    p {
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 20px;
      width: 85vw;
    }

    button {
      font-size: 16px;
      line-height: 23px;
    }

    .sanitize-content {
      margin: 5% 5%;
    }

    .sanitize-img {
      img {
        width: 95% !important;
      }
    }
  }

  .terms-cond {
    display: block;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 130%;
    color: $grey3;
    padding: 20px;
    margin-bottom: 0;
  }



  .button-active {
    padding: 10px 12.5px !important;
    font-size: 16px !important;
  }

  .button-inactive {
    padding: 10px 12.5px !important;
  }
}

.MultiCityForm {
  width: 100%;
  display: flex;
  flex-wrap: wrap;

  .add-leg-container {
    text-align: end;
    // margin-left: auto;
    margin-right: 50px;
  }
}

.react-switch-bg {
  border: 1.8px solid grey;
}

.react-switch-handle {
  border: 1.8px solid grey !important;
}
