@import "src/styles/variables";

.img-side-left-iq{
  img {
     margin: 0% 2.5%;
   // margin: -3% 7.5%;
   object-fit: cover;
   width:45%;
    }
}
.img-side-right-iq {
  img {
  //  margin: 0% 2.5%;
  margin: -5% 8%;
  object-fit: cover;
  width:35%;
  }
  
}
.img-side-left-iq.frame3-iq{
  img{
    width:60%;
  }
}
.img-side-left-iq.frame3-iq p.w330{
  width:100% !important;
}
.img-side-left-iq.frame3-iq .content-iq{
  padding-right: 10px;
}

.frame4-iq {
  margin-top: 210px !important;
  .content {
    margin-top: unset !important;
  }
}

.frame2-iq {
  top: -45px!important;
}

.frame5-iq {
  img {
    margin-top: -120px !important;
  }
}

.frame3-iq{
  margin-top: 200px !important;
}

.w330-iq {
  width: 350px;
}

.w422-iq {
  width: 340px;
}

.img-side-left-iq {
  display: flex;
  justify-content: flex-start;
  max-width: 1380px;
  width: 100%;
  margin: auto;
  .content-iq {
    margin-top: 0;
    max-width: 360px;
    margin-left: 50px;
    h3 {
       font-size: 48px;
       font-weight: 600;
       line-height: 52.8px;
      // font-style: normal;
      color: $grey1;
      overflow-wrap: break-word;
    }
  
    p {
       font-size: 20px;
      // line-height: 20px;
      color: $grey3;
      margin-top: 30px;
      // color: #1e2b3b;
    }

    button {
      color: white;
      background-color: $black;
      border: 1px solid #ffffff;
      box-sizing: border-box;
      border-radius: 100px;
      font-weight: 600;
      font-size: 18px;
      line-height: 23px;
      padding: 5px 10px;
    }
  }
}
.img-side-left-iq.frame1-iq .content-iq{
  padding-bottom:120px;
}
.img-side-right-iq.frame2-iq .content-iq{
  padding-bottom:120px;
}
.image-left-iq {
  margin-left: 100px;
}
.image-right-iq {
  margin-right: 100px;
}
.image-side-section-iq {
  // margin-top: 390px;
  position: relative;
  padding-bottom: 85px;
}

.img-side-right-iq {
  display: flex;
  justify-content: flex-end;
  max-width: 1380px;
  width: 100%;
  margin: auto;
  position: relative;
  top: -110px;
  right: 0;

  .content-iq {
    margin-top: 15%;
    // margin-right: 113px;
    width: 450px;
   // margin-right: 200px;
   // margin-left: 5%;
   padding-left: 100px;
    h3 {
       font-size: 48px;
      // font-weight: 600;
      // line-height: 52.8px;
      // font-style: normal;
      color: $grey1;
      overflow-wrap: break-word;
    }
    // h3 {
    //   font-style: normal;
    //   font-weight: normal;
    //   font-size: 16px;
    //   line-height: 20px;
    //   color: $grey3;
    //   margin-top: 30px;
    // }
    p {
       font-size: 20px;
      // line-height: 20px;
      // color: #9ca2a9;
      color: $grey3;
      margin-top: 30px;
    }
    button {
      color: white;
      background-color: $black;
      border: 1px solid #ffffff;
      box-sizing: border-box;
      border-radius: 100px;
      font-weight: 600;
      font-size: 18px;
      line-height: 23px;
      padding: 5px 15px;
    }
  }
}

@media screen and ($mobile-media) {
  .img-side-left-iq.frame3-iq img{
      width:100%;
  }
  .frame3-iq {
    margin-top:0px !important;
   // height: 300px;
}
  .img-side-right-iq {
    flex-direction: column-reverse;
  }
  .image-side-section-iq {
    margin-top: 135px;
    padding-bottom: unset;
    .frame1-iq {
      img {
        width: 100% !important;
      }
    }
    .frame4-iq {
      margin-top: unset !important;
    }
    .frame5-iq {
      img {
        margin-top: unset !important;
        width: 100% !important;
      }
    }
  }

  .img-side-left-iq,
  .img-side-right-iq {
    position: static;
    top: 0;
    flex-wrap: wrap;
    justify-content: unset;

    .content-iq {
      margin: 0;
      padding:55px 25px 55px 20px !important;
      width: 100%;
      max-width: 100%;

      h3 {
        letter-spacing: -0.02em;
        overflow-wrap: break-word;
        // font-size: 36px;
        // line-height: 46px;
        // font-size: 24px;
        // line-height: 31px;
        max-width: 335px;
      }

      p {
        width: 100%;
        // margin-top: 24px;
        margin-top: 35px;
        // font-size: 16px;
        // line-height: 150%;
      }

      button {
        margin: 5% 0;
      }
    }

    img {
      width: unset;
      margin: 0;
      height: 500px;
     
    }
    
    // .flight {
    //   height: 300px;
    // }

  }

  .img-side-left-iq.frame3-iq img{
    height: 300px;
  }
  .img-side-left-iq img{
    object-position: bottom;
  }
  .img-side-right-iq img {
    object-position: top;
  }
}

@media screen and ($tablet-media-min) and ($tablet-media) {
  .img-side-left-iq.frame3-iq img {
    width: 50%;
}
  .frame4-iq {
    margin-top: 160px !important;
  }

  .image-side-section-iq {
    margin-top: 50px;
    padding-bottom: unset;
  }
  .img-side-left-iq {
    margin-left: 0;
    .content-iq {
      width: 50%;
      margin-left: 0;
      margin-right: 3%;
      h3 {
        font-size: 35px;
        line-height: 40px;
        overflow-wrap: break-word;
      }
      p {
        width: unset;
        font-size: 15px;
      }
    }
  }
  .img-side-right-iq {
    right: 0;
    .content-iq {
      width: 50%;
      margin-left: 3%;
      margin-right: 0;
      h3 {
        font-size: 35px;
        line-height: 40px;
        overflow-wrap: break-word;
      }
      p {
        width: unset;
        font-size: 15px;
      }
    }
  }

  .img-side-left-iq,
  .img-side-right-iq {
    img {
      width: 45%;
      margin: 0 2.5%;
    }
  }

  .concierge-main-iq {
    padding-bottom: unset;
  }
}

@media screen and ($tablet-media-min-hz) and ($tablet-media-hz) {
  .frame4 {
    margin-top: 160px !important;
  }

  .image-side-section {
    margin-top: 50px;
    padding-bottom: unset;
  }
  .img-side-left {
    margin-left: 0;
    .content {
      width: 50%;
      margin-left: 0;
      margin-right: 3%;
      h3 {
        font-size: 35px;
        line-height: 40px;
        overflow-wrap: break-word;
      }
      p {
        width: unset;
        font-size: 15px;
      }
    }
  }
  .img-side-right {
    right: 0;
    .content {
      width: 50%;
      margin-left: 10%;
      margin-right: 0;
      h3 {
        font-size: 35px;
        line-height: 40px;
        overflow-wrap: break-word;
      }
      p {
        width: unset;
        font-size: 15px;
      }
    }
  }

  .img-side-left,
  .img-side-right {
    img {
      width: 45%;
      margin: 0 2.5%;
    }
  }

  .concierge-main {
    padding-bottom: unset;
  }
}
