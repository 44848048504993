@import "src/styles/variables";

.footer-main {
  background-color: $black;
}

.footer-root {
  color: $white;
  padding-top: 2.5%;
  padding-bottom: 3.5%;
  max-width: 1440px;
  width: 100%;
  margin: auto;

  footer {
    display: flex;
    margin-top:20px;
    // flex-wrap: wrap;
  }
}

.footer-root-main {
  width: 75%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  .footer-sections-c {
    flex-basis: 100%;
  }
}

.footer-img {
  display: flex;
  justify-content: center;
}

.footer-sections {
  width: 33.3%;
  padding: 0 2vw;
}

.footer-sections-logo {
  width: 20%;

  img {
    width: 100%;
    margin: 0px 15px 0px 15px !important;
    max-width: 190px;
  }
}

.footer-sections-si {
  width: 15%;

  img {
    margin: 0 10px;
  }
}

.logo-title {
  text-transform: uppercase;
  font-size: 23px;
  letter-spacing: 0.05em;
  padding: 2px;
}

.footer-section-title {
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 22px;
  padding-bottom: 10px;
}

.footer-sections-c {
  position: relative;
  left: 1%;
  width: 100%;
  img {
    width: 10vw;
  }
  .mid-section {
    margin: 0 1vw;
    width: 30%;
  }

  .inner-section {
    display: flex;
    align-items: center;
    flex-direction: row;
  }
  // .footer-c-5{
  //   top:0.2vw;
  //   width:62%;
  //   margin: 0 0.5vw 0 0;
  // }
  .footer-c-6 {
    position: relative;
    padding-left: 35px;
    // top:0.2vw;
    // width:60%;
    // margin: 0 0.5vw 0 0;
  }
  .footer-c-1 {
    margin: 0;
    width: 98%;
    position: relative;
  }
  .footer-c-2 {
    position: relative;
    top: 0.2vw;
    width: 62%;
    margin: 0 0.5vw 0 0;
  }
  .footer-c-3 {
    position: relative;
    top: 0.1vw;
    margin: 0;
    width: 30%;
  }
  .footer-c-4 {
    position: relative;
    width: 92%;
    margin: 0;
  }
  .footer-c-5 {
    width: 30%;
  }
}

.footer-section-list {
  list-style: none;
  padding-inline-start: 0px !important;

  li {
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 41px;
    letter-spacing: 0.05em;
    text-transform: uppercase;

    a {
      color: $white;
    }
  }
}

@media screen and ($mobile-media) {
  .footer-main {
   margin-top:-1px;
   padding-top:50px;
  }
  .footer-section-list  li{
    font-size: 12px;
  }
  .footer-sections-c{
    margin-top: -70px;
  }
  .footer-sections-c a {
    text-align: center;
    display: block;
    width: 100% !important;
}
.footer-sections-c a img {
    width: 100% !important;
    height: auto !important;
    margin: 0px 0px 20px !important;
    padding: 0px !important;
    max-width: 70px !important;
    min-width: 50px !important;
}
.footer-sections-c a img.footer-c-5{
  max-width:50px !important;
}
  footer {
    display: flex;
    margin: 0px 20px 30px 20px !important;
    flex-wrap: wrap;
  }
  .footer-root{
    padding-top:0px !important;
  }
  .footer-root-main {
    width: 100vw !important;
  }
  .footer-section-title{
     padding-bottom:0px;
  }
  .footer-section-list{
    margin-bottom: 5px;
  }

  .footer-sections-logo {
    width: 100%;
    padding-top:0;
    padding-bottom:100px;

    img {
      width: 190px;
    }
  }

  .footer-sections-c {
    width: 50% !important;
    flex-basis: 25% !important;

    position: relative;

    .inner-section {
      display: block !important;
    }

    .mid-section {
      width: 100%!important;
    }
    .footer-c-1 {
      position: relative !important;
      left: 7% !important;
      width: 30vw !important;
    }
    .footer-c-2 {
      width: 28vw !important;
      display: inline-block !important;
      margin-bottom: 5px !important;
    }
    .footer-c-3 {
      width: 11vw !important;
      display: inline-block !important;
      margin-bottom: 10px !important;
    }
    .footer-c-4 {
      position: relative !important;
      left: 8%;
      width: 15vw !important;
    }
    .footer-c-5 {
      width: 30%;
      margin: 3%;
      margin-top: 11%;
    }
    .footer-c-6 {
      position: relative;
      padding-left: 5px;
      // top:0.2vw;
      width: 40%;
      // margin: 0 0.5vw 0 0;
    }
  }
  .footer-img {
    display: flex;
    justify-content: center;
  }

  .footer-sections {
    width: 50%;
    margin-bottom: 25px;
  }

  .footer-sections-si {
    width: 100%;
    display: flex;
    justify-content: center;

    img {
      margin: 0 15px;
    }
  }
}

@media screen and ($tablet-media-min) and ($tablet-media) {
  
  footer {
    flex-wrap: wrap;
  }

  .footer-root-main {
    flex-wrap: nowrap;
    width: 100vw;
  }

  .footer-sections-c {
    width: 25% !important;
    flex-basis: 0% !important;
    left: 0 !important;

    position: relative;

    .inner-section {
      display: block !important;
    }

    .mid-section {
      width: 24vw !important;
      margin: 13px 0 !important;
    }
    .footer-c-1 {
      position: relative !important;
      left: 2% !important;
      width: 15vw !important;
    }
    .footer-c-2 {
      width: 15vw !important;
      display: inline-block !important;
    }
    .footer-c-3 {
      width: 9vw !important;
      display: inline-block !important;
    }
    .footer-c-4 {
      position: relative !important;
      left: 5%;
      width: 11vw !important;
    }
    .footer-c-5 {
      width: 70%;
      margin: 3%;
      margin-top: 11%;
    }
  }
  .footer-sections-c .mid-section {
    width: 100% !important;
    margin-bottom:0px !important;
}
  .footer-sections-c a {
    text-align: center;
    display: block;
    width: 100% !important;
  }
  .footer-sections-c a img {
      width: 100% !important;
      height: auto !important;
      margin: 0px 0px 20px !important;
      padding: 0px !important;
      max-width: 70px !important;
      min-width: 50px !important;
  }
  .footer-sections-c a img.footer-c-5{
    max-width:50px !important;
  }

  .footer-sections-logo {
    width: 100%;
    margin-bottom: 5%;
  }

  .footer-sections {
    width: 25%;
    padding: 0 5%;
  }

  .footer-sections-si {
    width: 100%;
    display: flex;
    justify-content: center;
  }
}
@media screen and (max-width:1199px) {
  .footer-sections-si img{
    margin: 0 7px;
  }
}