@import 'src/styles/variables';

.root-section {
  height: 100%;
  background-color: $black;
}

.hiw-section-1 {
  position: static;
  width: 100%;
  height: 100vh;
  left: 0px;
  top: 80px;
  background: #000000;
  flex: none;
  order: 1;
  flex-grow: 0;
  margin: 0px 0px;
}

.hiw-heading{
  top: 15% !important;
  font-size: 180% !important;
}

.hero {
  color: $grey1;
  font-weight: bold;
  position: relative;
  width: 80vw;
  height: 160px;
  margin:0 auto;
  top: 13vh;
  font-size: 340%;
  line-height: 100%;
  display: block;
  align-items: center;
  text-align: center;
}

.about-us {
  color: $grey1;
  position: relative;
  width: 75%;
  height: 160px;
  margin:0 auto;
  top: 25vh;
  font-size:180%;
  line-height: 36px;
  display: flex;
  align-items: center;
  text-align: center;
}

.scroll-icon {
  position: relative;
  width: clamp(40px , 5vw, 60px);
  height: auto;
  left: 0;
  display:block;
  margin:auto;
  top: 50vh;
}

.services-section {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  max-width: 1440px;
  width: 100%;
  margin: auto;
}

.spacer {
  position: static;
  max-width: 1440px;
  width: 100%;
  height: 150px;
  left: 0px;
  top: 1281px;
  background: #000000;
}

@media screen and ($mobile-media) {
  .hero {
    font-style: normal;
    font-weight: bold;
    font-size: clamp(30px,9vw,48px);
    line-height: 100%;
    flex-wrap: wrap;
    align-items: center;
    text-align: center;
    letter-spacing: -0.01em;
    color: $grey1;
    position: relative;
    width: 100%;
    top: 7vh;
    left: 0;
    padding: 0 22px;
  }

  .hiw-heading{
    top: 18% !important;
    font-size: clamp(45px,283%,48px) !important;
  }

  .about-us {
    color: $grey1;
    font-style: normal;
    font-size: 4vw;
    line-height: 23px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    text-align: center;
    letter-spacing: -0.01em;
    position: relative;
    width: 90%;
    left: 0;
    padding: 0 22px;
   // margin-bottom: calc(2vh + 2vw);
    height: auto;
    top: auto;
  }

  .hiw-section-1 {
    height: auto;
    padding-top:30px;
  }

  .scroll-icon {
    left: 0;
    width:13%;
    display: block;
    margin:auto;
    height: auto;
    top: auto;
  }
  .hiw-section-1{
    height: auto;
   // margin-bottom: 100px;
    h1{
      height: auto;
      top: 0px;
      padding-top: 80px;
    }
    .scroll-icon{
      top: 15px;
    }
    h4 {
      font-style: normal;
      line-height: 35px;
      font-size: 22px;
      height: auto;
      top: 0px;
      padding-top: 80px;
    }
  }
}

@media screen and ($tablet-media-min) and ($tablet-media) {
  

  .hiw-section-1 {
    padding-top: 15px;
    height: auto;
   // margin-bottom: 100px;
    h4 {
      font-style: normal;
      line-height: 35px;
      font-size: 22px;
      height: auto;
      top: 0px;
      padding-top: 80px;
     // margin-bottom: 30px;
    }

    .scroll-icon {
      position: relative;
      width: clamp(40px , 5vw, 60px);
      height: auto;
      left: 0;
      display:block;
      margin:auto;
      top: 15px;
   }
   h1{
    height: auto;
    top: 0px;
    padding-top: 80px;
  }
   
}

  .hero {
    color: $grey1;
    position: relative;
    width: 80vw;
    height: 160px;
    margin:0 auto;
    font-weight: bold;
    top: 13vh;
    font-size: clamp(250%, 280%, 350%);
    line-height: 100%;
    align-items: center;
    text-align: center;
  }

  .hiw-heading{
    top: 20% !important;
    font-size: clamp(46px,310%,48px) !important;
  }

  .scroll-icon {
    top: clamp(300px,45vh,500px);
    width: clamp(50px,8vw,100px);
    left: 0;
    display: block;
    margin:auto;
  }
  .about-us {
    //margin-bottom: calc(16vh + 2vw);
    font-size:3.5vw;
    line-height: 35px;
  }
  
  
}

@media screen and ($tablet-media-min-hz) and ($tablet-media-hz) {

  .hero {
    font-size:clamp(280%, 300%, 370%);
    width: 70vw;
  }
  .hiw-section-1{
    height: auto;
   // margin-bottom: 100px;
    h1{
      height: auto;
      top: 0px;
      padding-top: 80px;
    }
    .scroll-icon{
      top: 15px;
    }
    h4 {
      padding-top: 80px;
      font-size: 22px;
     // margin-bottom: 30px;
    }
  }
  .scroll-icon {
    width: clamp(30px,6vw,60px);
    left: 0;
    display: block;
    margin:auto;
    height: auto;
    top: auto;
    margin-bottom: 50px;
  }
  .about-us {
    font-size: 150%;
    line-height: 36px;
   // margin-bottom: calc(13vh + 2vw);
    height: auto;
    top: auto;
  }
}
