@import 'src/styles/variables';

.Testimonials{
    height: 100%;
    background-color: $black;
    
    .head-section-1 {
      overflow-wrap: break-word;
      position: static;
      width: 100%;
      height: 100vh;
      background: #000000;
      padding-top: 15\vh;
      flex: none;
      order: 1;
      flex-grow: 0;
      margin: 0px 0px;
      .Forbes{
          max-width: 188px;
          max-height: 73px;
          width: auto;
          height: auto;
          margin: 0 auto;
          display: block;
      }
      .testimonial-scroll-icon{
        display: block;
        margin: auto;
        padding-top: 100px;
      }
    }
    
    .testimonial-heading{
        font-size: 250%;
      }
    
      .head {
        color: $grey1;
        font-weight: 600;
        width: 85vw;
        height: 200px;
        margin:0 auto;
        font-family: $font-medium;
        display: block;
        align-items: center;
        text-align: center;
      }

      .Statements{
        max-width: 1300px;
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 130px;
        grid-row-gap: 260px;
        margin: 0 auto;
        padding: 0px 25px;
        padding-bottom: 140px;
        .Statement{
            .icon{
                max-width: 50px;
                max-height: 50px;
                width: auto;
                height: auto;
            }
            .text{
                margin-top: 33px;
                color: $white;
                font-weight: 600;
            }
            .person{
                margin-top: 30px;
                color: $grey1;
                margin-bottom: 0;
            }
            .place{
                color: $grey1;
            }
        }
      }
      
      @media screen and ($tablet-media-min) and ($tablet-media){
        .head {
            margin-bottom: clamp(70px, 36vw, 80px) !important; 
        }
        .head-section-1 {
            padding-top:20vh !important;
        }
        .testimonial-scroll-icon{
            padding-top:50px !important;
        }
    }
 
    @media screen and ($mobile-media){
        .Statements{
            grid-template-columns: 1fr !important;
            grid-row-gap: 120px !important;
        }
        .head {
            margin-bottom: clamp(140px, 36vw, 150px) !important; 
            width: 80vw !important;
        }
        .head-section-1 {
            padding-top:5vh !important;
        }
        .testimonial-heading{
            font-size: clamp(200%, 210% , 220%) !important;
          }
        .testimonial-scroll-icon{
            padding-top:50px !important;
        }
    }
}