@import 'src/styles/variables';

.notification{
    position: fixed;
    width: 100vw;
    z-index:7;
    background-color: $blue;
    cursor: pointer;
    .text{
        text-align: center;
        margin: 0;
        padding: 10px 0px;
        color: $white;
    }
    a{
        color: $white;
        text-decoration: underline;
    }
    @media screen and ($mobile-media){
        .text{
            font-size: small !important;
        }
    }
}
.loggedInModal{
    .close{
        display: none !important;
    }
    .modal-content{
        top: 2% !important;
        .heading{
            font-size: 22px;
        }
    }
    h5{
        font-size: 18px;
        a:hover{
            text-decoration: none;
        }
        text-align: center;
        margin-bottom: 10px;
    }
}
.OfferModal{
    .modal-content{
        margin-top:30px;
        width: 400px;
        background-color: $blue;
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        color: $white;
        h2{
            padding: 2%;
            padding-top: 42px;
            padding-bottom: 15px;
        }
        h6{
            font-size: 18px;
            padding: 30px;
            padding-bottom:18px;
            padding-top: 0;
        }
        .terms{
            font-size: 14px;
            padding: 0;
            a{
                color: $white;
                text-decoration: underline;
            }
        }
        .Button{
            margin-bottom: 30px !important;
        }
        .icon{
            width:46px;
            height:auto;
        }
        .close{
            align-self: flex-end;
            svg{
                background:white;
                border-radius:20px;
            }
        }
    }
    @media screen and ($mobile-media){
        .modal-content{
            margin: auto !important;
            width: 80% !important;
            h6{
                padding: 0 2% !important;
                padding-bottom:18px !important;
                padding-top: 0 !important;
            }
            .terms{
                font-size: 13px !important;
                padding: 0 !important;
            }
        } 
 
    }
}

.peekModal{
    .modal-content{
        top: 140px;
        background: $error-red;
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        color: #FFFFFF;
        width: 400px;
        height: 590px !important;
        .peek-title {
            position: absolute;
            top: 91px;
            left: 15px;
            width: 369px;
            line-height: 31px!important;

            p {
                margin-bottom: 0rem!important;
            }
        }
        .icon{
            width: 46px;
            height:auto;
            position: absolute;
            top: 6.08%;
            bottom: 87.2%;
            color: #F1F4F9;
        }
        .close{
            align-self: flex-end;
            svg{
                background:white;
                border-radius:20px;
            }
        }
        .break-body {
            word-break: break-word;
        }

        .email-vector {
            position: absolute;
            left: 36px;
            top: 290px;
            display: flex;
        }
        .chat-vector {
            position: absolute;
            left: 36px;
            top: 375px;
            display: flex;   
        }
        .phone-vector {
            position: absolute;
            left: 36px;
            top: 455px;
            display: flex;   
        }

        .vector-icon {
            width: 44px;
            color: #F1F4F9;
        }

        .phone-icon {
            margin-top: 5px;
        }

        .service-list {
            margin-left: 30px;
            a{
                color: $primary-gray;
            }
        }
        .service-title {
            position: relative;
            // top: 5px;
            text-align: left;
            margin-bottom: 0px
        }
        .condition-title {
            font-size: 13px;
            line-height: 17px; 
            a{
                color: #FFFFFF;
                text-decoration: underline;
            } 
        }
        .condition-div {
            position: absolute;
            top: 555px;
            display: flex;
            align-items: center;
            text-align: center;
        }
    }
    @media screen and ($mobile-media){
        .modal-content{
            margin: auto !important;
            width: 90% !important;
            top: 64px;
            .peek-title {
                // left: 0px;
                // width: 100%;
                 left: 3px;
                 right: 3px;
                 width: 96%;
            } 
            .email-vector {
                left: 10px;
                width: 92%;
            }
            .chat-vector {
                left: 10px; 
                width: 92%; 
            }
            .phone-vector {
                left: 10px; 
                width: 92%; 
            }

           .service-list {
            margin-left: 13px;
           }
        }  
           
    }
    @media screen and ($tablet-media-min-hz) and ($tablet-media-hz) {
        .modal-content{
            margin: auto !important;
        }
    }
    @media screen and ($tablet-media-min) and ($tablet-media) {
        .modal-content{
            margin: auto !important;
            top: 64px;
        }
    }

}