@import "src/styles/variables";

.header-main-dark {
  padding-top: 45px;
  background-color: $black;
  position: relative;
  z-index: 4;
}

// .header-main-dark-auth {
//   background-color: $black;
// }

.header-main-light {
  background-color: white;
}

.header-right {
  margin-right: 50px;
}

.header-root {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 80px;
  width: 100%;
  max-width: 1440px;
  margin: auto;
}

.theme-light {
  color: white;

  &:hover {
    color: white;
  }
}

.theme-dark {
  color: $black;

  &:hover {
    color: $black;
  }
}

.ab1-nav-links {
  text-transform: uppercase;
  font-style: normal;
  font-weight: 600;
  font-size: clamp(14px, 1.4vw, 16px);
  letter-spacing: 0.05em;
  margin: 0 clamp(15px, 1.5vw, 20px);
  cursor: pointer;

  &:hover {
    text-decoration: none;
  }
}

.brand-logo {
  margin: 10px 40px;
}

#mobile-menulist {
  display: none;
}

.mobile-header-sections {
  display: none;
}

.nav-grp {
  position: relative;
  //left: 5%;
  // position: absolute;
  // margin-left: -7%;
}

@media screen and ($mobile-media) {
  .header-main-dark {
    margin-bottom:-1px;
  }
  .header-root {
    height: 60px;
  }
  .mobile-header-sections {
    display: flex;
    align-items: center;
    margin-right: 10px;
  }

  .header-sections {
    display: none;
  }

  .brand-logo {
    margin: 0px ;
  }

  .header-menu {
    margin: 25px 15px;
  }

  #mobile-menulist {
    display: none;
    background-color: #000;
    padding: 5% 20px;
    // height: 100%;
    position: fixed;
    width: 100%;
    overflow-y: hidden;
    z-index: 3;
    transition: ease-in(2s);

    .ab1-nav-links {
      display: block;
    }

    .grp-list {
      padding: 10px 0;
    }

    .unq-list {
      padding: 30px 0;
    }
  }
}

@media screen and ($tablet-media-min) and ($tablet-media) {

  .mobile-header-sections {
    display: flex;
    align-items: center;
    margin-right: 20px;
  }

  .header-sections {
    display: none;
  }

  .brand-logo {
    margin: 0px 15px;
  }

  .header-menu {
    margin: 25px 15px;
  }

  #mobile-menulist {
    display: none;
    background-color: #000;
    padding: 5% 20px;
    // height: 100%;
    position: fixed;
    width: 100%;
    overflow-y: hidden;
    z-index: 5;
    transition: ease-in(2s);

    .ab1-nav-links {
      display: block;
    }

    .grp-list {
      padding: 10px 0;
    }

    .unq-list {
      padding: 30px 0;
    }
  }
}

@media screen and ($tablet-media-min-hz) and ($tablet-media-hz) {
  .ab1-nav-links {
    font-size: clamp(12px, 1.4vw, 14px);
    letter-spacing: 0;
    margin: 0 5px;
  }
  .nav-grp {
    left: 0;
  }

  .right-menu {
    margin-right: 30px;
  }
}

@media not screen and ($tablet-media) {
  #mobile-menulist {
    display: none !important;
  }
}
